


    .admin-layout-wrapper{
        min-height: 100vh;
        display: flex;
        flex-direction: column;
    }

    .admin-outer-grid {
        flex-direction: row;
        flex: 100%;
    }

    .side-menu-container, .container.side-menu-container {
        width: 180px;
        flex:0 0 180px;
        padding-inline-end: 0;
    }

    .side-menu {
        width: 100%;
        flex: 100%;

        .side-menu-logo {
            display: block;
            height: 100px;
            width: 100px;
            margin: var(--margin-xl) auto var(--margin-l) auto;
        }

        .side-nav {
            list-style: none;
            padding-inline-start: 20px;

            li {
                margin-bottom: var(--margin-l);
            }


            a:not(.logo) {
                display: block;
                width: 100%;
                list-style: none;
                color: var(--c-bg);
                font-size: var(--font-l);
                padding: var(--margin-s);
                position: relative;
                padding-inline-start: var(--margin-s);
                padding-inline-end: var(--margin-s);
                transform: none;
                transition: padding 100ms ease-in;

                &.uk-active, &:hover {
                    text-decoration: none;
                    padding-inline-start: var(--margin-m);
                    &:before {
                        position: absolute;
                        content: ' ';
                        width: 3px;
                        height: 100%;
                        inset-inline-start: 0;
                        top: 0;
                        background: var(--c-alt);
                    }
                }
            }
        }


    }

    .admin-notification-widget {
        .notification-icon {
            position: relative;
            cursor: pointer;

            .notification-badge {
                position: absolute;
                top: -70%;
                right: -50%;
                z-index: 2;
            }
        }
    }
