
    .uk-button {
        position: relative;

        .button-icon--start {
            display: inline-block;
            margin-inline-end: 2px;
        }

        .button-icon--end {
            display: inline-block;
            margin-inline-start: 2px;
        }
    }

    .uk-button:not(.uk-button-small) .button-inner {
        display: flex;
        align-items: center;
        justify-content: center;

        .height-keeper {
            width: 0;
            pointer-events:none;
            overflow:hidden;
            color: transparent
        }
    }

    .uk-button.uk-button-small {
        height: calc(var(--global-margin) * 1.5);

        .button-inner {
            height: calc(var(--global-margin) * 1.5);
        }
    }

    .uk-button.uk-button-medium {
        height: calc(var(--global-margin) * 2 );

        .button-inner {
            height: calc(var(--global-margin) * 1.5);
        }
    }

    .uk-button.uk-button-large {
        height: calc(var(--global-margin) * 2.6 + 3px);

        .button-inner {
            height: calc(var(--global-margin) * 2.6 + 3px);
        }
    }

    .uk-button.uk-font-bold {
       .button-text{
           position: relative;
           top:  calc(var(--global-margin) * -0.1);
       }
    }

    .uk-button.uk-button-loading .button-inner {
        .button-text, .button-icon {
            opacity: 0;
            color: transparent;
            pointer-events: none;
            visibility: hidden;
        }
    }

    .uk-button.uk-button-loading, .uk-button.uk-button-loading[disabled] {
        cursor: wait;
    }

    .uk-button[disabled] {
        cursor: not-allowed;
    }

    .button-text {
        white-space: nowrap;
    }
