
.select2-item-icon {
    padding: calc(var(--margin-m) * 0.25) calc(var(--margin-m) * 0.25);
    margin-bottom: calc(var(--margin-m) * 0.75);
    background: var(--c-bg);
    border-inline-start: 4px solid var(--c-gray);
    transition: padding 150ms ease-out 0ms, border-color 150ms ease-out;

    &.selected, &.selected:hover {
        border-inline-start-color: var(--c-lead);
        font-weight: 500;
    }
    &:hover {
        border-inline-start-color: var(--c-lead);

        .select2-item-icon__icon {
            margin-inline-start: var(--margin-xs);
            margin-inline-end: var(--margin-s);
        }


    }

    &.highlight, &.highlight:hover {
        padding-inline-start: calc(var(--margin-m) * 0.3);
        border-inline-start-color: var(--c-lead);

        .select2-item-icon__icon {
            margin-inline-end: calc(var(--margin-m) * 0.35);
        }
    }

}
.select2-item-icon__icon {
  display: flex;
  margin-inline-end: calc(var(--margin-m) * 0.2);
  min-height: calc(var(--margin-m) * 1.5);
  transition: margin 150ms ease-out 90ms;


}


