
// scrollbar styles for drop down

.select2-dropdown {
    $scrollbar-bg: var(--global-muted-background);
    $scrollbar-color: var(--global-muted-color);

    scrollbar-color: $scrollbar-color $scrollbar-bg;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
        width: 4px;
    }

    &::-webkit-scrollbar-track {
        background: $scrollbar-bg;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.05);
    }

    &::-webkit-scrollbar-thumb {
        background-color: $scrollbar-color;
        outline: none;
    }

    &.uk-open {
        border: 1px solid var(--global-primary-background);
    }
}

.dropdown-option-wrapper {
    position: relative;
    background: var(--global-muted-background);


}

.select2-dropdown > * {
    cursor: pointer;
}

.filter-wrapper{
    align-items: center;
}
.select2-filter-input {

}
.select2-dropdown-close {
    margin-inline-start: calc(var(--global-margin) * 0.5);
    transition: opacity 125ms ease;
    &:hover {
        opacity: 0.8;
    }
}



