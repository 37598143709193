

.side-cut-layout {
    @media (max-width: 768px) {
       padding-top: 10vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-bottom: 10vh;
    }
}
.main-content {
    clip-path: polygon(0 0, 40% 0, 49.75% 49.75%,  49.75% 50.25%, 40% 100%, 0% 100%);

    @media (max-width: 1200px) {
        clip-path: polygon(0 0, 60% 0, 69.75% 49.75%,  69.75% 50.25%, 60% 100%, 0% 100%);
    }

    @media (max-width: 767px) {
        position: relative;
        clip-path: none;
        max-height: 80vh;
        min-height: 60vh;
        overflow: scroll;
        width: 90vw;
        margin-left: auto;
        margin-right: auto;
        flex: 0; // in this resolution we are under flex, and we want to be tight
        border-radius: var(--border-radius-m);
    }

    &[dir="rtl"] {
        clip-path: polygon(100% 0, 60% 0, 30% 100%, 100% 100%);

        @media (max-width: 1200px) {
            clip-path: polygon(100% 0, 90% 0, 50% 100%, 100% 100%);
        }

        @media (max-width: 767px) {
            clip-path: none;
        }
    }


    .content-area {
        width: 40%;
        min-height: 100vh;

        @media (max-width: 1200px) {
            width: 50%;
        }

        @media (max-width: 767px) {
            width: 100%;
            min-height: 0;
            padding: var(--margin-max) var(--margin-xl);
        }
    }
    .inner-content-block {
        min-height: 100vh;

        @media (max-width: 767px) {
            min-height: 0;
        }
    }
}
