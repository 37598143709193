
    .offcanvas-nav {
        position: fixed;
        top: 0;
        inset-inline-start: 0;
        min-width: 300px;
        padding: calc(var(--margin-max) * 2) var(--margin-l) var(--margin-l) var(--margin-l);

        .menu-close-icon {

        }
    }



