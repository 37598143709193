
    .legal--light,
    .legal--text {
        p,span,a {
            color: var(--c-text);
        }

        a {
            text-decoration: underline;
        }
    }

    .legal--dark,
    .legal--bg{
        p, span, a {
            color: var(--c-bg);
        }

        a {
            text-decoration: underline;
        }
    }
